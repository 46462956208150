import React, {
  Children,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';

import Box from '../Box';

import styles from './Collapsible.module.scss';

interface CollapsibleProps {
  readonly children?: ReactNode;
  collapsed?: boolean;
  header?: ReactNode;
  className?: string;
  headerStyles?: string;
  minHeight?: string;
  centerExpand?: boolean;
  expandTextClosed?: string;
  expandTextOpen?: string;
  contentStyle?: string;
  tabStyle?: string;
}

const Collapsible: FC<CollapsibleProps> = ({
  children,
  collapsed = true,
  header,
  className,
  headerStyles,
  minHeight = '0px',
  centerExpand = false,
  expandTextClosed = '',
  expandTextOpen = '',
  contentStyle,
  tabStyle
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const contentRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const childCount = Children.toArray(children).filter(Boolean).length;

  useEffect(() => {
    if (isCollapsed) {
      bodyRef.current?.setAttribute('style', `max-height: ${minHeight}`);
    } else {
      const h = contentRef.current?.getBoundingClientRect().height || 0;
      bodyRef.current?.setAttribute('style', `max-height: ${h}px`);

      if (childCount < 1) {
        setIsCollapsed(true);
      }
    }
  }, [isCollapsed, setIsCollapsed, childCount, minHeight]);

  return (
    <Box className={classNames(styles.root, className)}>
      {header && (
        <div className={styles.header}>
          <div className={classNames(styles.headerContent, headerStyles)}>
            {header}
          </div>
          <div className={styles.toggle}>
            <button
              type="button"
              className={styles.expandButton}
              disabled={childCount < 1}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <i className="material-icons">
                {isCollapsed ? 'expand_more' : 'expand_less'}
              </i>
            </button>
          </div>
        </div>
      )}
      <div ref={bodyRef} className={styles.body}>
        <div
          ref={contentRef}
          className={classNames(styles.content, contentStyle)}
        >
          {children}
        </div>
      </div>
      {centerExpand && (
        <div className={classNames(styles.center, tabStyle)}>
          <button
            type="button"
            className={styles.expandButton}
            disabled={childCount < 1}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <span>{isCollapsed ? expandTextClosed : expandTextOpen}</span>
            <i className="material-icons">
              {isCollapsed ? 'expand_more' : 'expand_less'}
            </i>
          </button>
        </div>
      )}
    </Box>
  );
};

export default Collapsible;
