import { Row } from 'components/modals/rows/PartsRow';
import { OrderItemsQuery } from 'types/graphql';

export const getSparePartsIndices = (
  sparePartData: OrderItemsQuery['orderItems']['edges'],
  rows: Row[]
) => {
  // const rowsData = sparePartData.sparePartOrderItems.edges;
  const selectedParts = rows.map(
    (row) => `${row?.orderSerialNumber}-${row?.sparePartId}`
  );

  const indices: number[] = [];
  sparePartData.forEach((orderItem, index) => {
    if (
      selectedParts.includes(
        `${orderItem.order.serialNumber}-${orderItem?.sparePart?.id}`
      )
    ) {
      indices.push(index);
    }
  });

  return indices;
};
