import { lazy } from 'react';

export default {
  Portal: lazy(() => import('./Portal')),
  Login: lazy(() => import('./Login')),
  NotFound: lazy(() => import('./NotFound')),
  Forgot: lazy(() => import('./Forgot')),
  Reset: lazy(() => import('./Reset')),
  Users: lazy(() => import('./Users')),
  Retailer: lazy(() => import('./Retailer')),
  Warranties: lazy(() => import('./Warranties')),
  Warranty: lazy(() => import('./Warranty/Warranty')),
  CreateWarranty: lazy(() => import('./CreateWarranty')),
  ExplodedView: lazy(() => import('./ExplodedView')),
  ExplodedViews: lazy(() => import('./ExplodedViews')),
  Demo: lazy(() => import('./Demo')),
  Vehicles: lazy(() => import('./Vehicles')),
  Settings: lazy(() => import('./Settings')),
  TermsAndConditions: lazy(() => import('./TermsAndConditions')),
  Consignment: lazy(() => import('./Consignment')),
  MyOrders: lazy(() => import('./MyOrders')),
  MyOrder: lazy(() => import('./MyOrder')),
  MyInvoices: lazy(() => import('./MyInvoices')),
  MyInvoice: lazy(() => import('./MyInvoice')),
  OperationGroups: lazy(() => import('./OperationGroups')),
  OperationGroup: lazy(() => import('./OperationGroup')),
  Webshop: lazy(() => import('./Webshop')),
  WebshopSingle: lazy(() => import('./WebshopSingle')),
  WebshopCart: lazy(() => import('./WebshopCart')),
  WebshopCheckout: lazy(() => import('./WebshopCheckout')),
  WebshopCheckoutConfirm: lazy(() => import('./WebshopCheckoutConfirm')),
  Showroom: lazy(() => import('./Showroom')),
  SparePartsGuide: lazy(() => import('./SparePartsGuide')),
  SparePartsList: lazy(() => import('./SparePartsList')),
  SparePart: lazy(() => import('./SparePart')),
  WebshopSearch: lazy(() => import('./Webshop/sections/WebshopSearch')),
  ProductRules: lazy(() => import('./Admin/ProductRules')),
  ProductRuleDetail: lazy(() => import('./Admin/ProductRuleDetail')),
  VolumeDiscounts: lazy(() => import('./Admin/VolumeDiscounts')),
  MaintenanceTask: lazy(() => import('./MaintenanceTask')),
  MaintenanceTasks: lazy(() => import('./MaintenanceTasks')),
  CreateMaintenanceTask: lazy(() => import('./CreateMaintenanceTask')),
  DealyMaintenanceTasks: lazy(() => import('./DealyMaintenanceTasks')),
  RetailerMaintenanceTasks: lazy(() => import('./RetailerMaintenanceTasks')),
  MaintenanceTaskTender: lazy(() => import('./MaintenanceTasks/Tender'))
};
