import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { Crumb, WebshopBreadcrumb } from '../index';
import WebshopUpdateShoppingList from '../WebshopUpdateShoppingList';
import { Container } from 'components/ui/general';
import { ConfirmModal } from 'components/ui/modals';
import { Drawer } from 'components/ui/navigation';
import Table from 'components/ui/table';
import { ExpandableCell } from 'components/ui/table/components/Cells/ExpandableCell';
import { OperationButtonsCell } from 'components/ui/table/components/Cells/OperationButtonsCell';
import { TABLE_LIMIT } from 'consts/defaults';
import { useFeedback } from 'hooks';
import { getShoppingListsCustom } from 'queries/queries';
import { getActingAsRetailer, getUser } from 'redux/auth';
import { addItem, getCartHasItems, getItems } from 'redux/cart';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';
import {
  BaseSorting,
  ShoppingList,
  useDeleteShoppingListMutation,
  UserRole
} from 'types';
import { PageResponse } from 'types/custom/types';
import { formatDate } from 'utils/dateFormat';
import { shoppingListResolvers } from 'utils/resolvers';

import styles from './WebshopShoppingLists.module.scss';

interface WebshopSectionProps {
  id?: string;
  section: string;
}

const WebshopShoppingLists = ({ section }: WebshopSectionProps) => {
  const dispatch = useDispatch();
  const actingAsRetailer = useSelector(getActingAsRetailer);
  const currentUser = useSelector(getUser);
  const cartHasItems = useSelector(getCartHasItems);
  const cartItems = useSelector(getItems);
  const label = webshopSectionTypeToText(section as WebshopSectionType);
  const defaultSorting = [{ id: 'createdAt', desc: true }];
  const [showImportFeedback, setShowImportFeedback] = useState(false);
  const crumbs: Crumb[] = [{ label, id: section }];
  const [selectedImport, setSelectedImport] = useState<ShoppingList | null>(
    null
  );
  const [selectedItem, setSelectedItem] = useState<ShoppingList | null>(null);
  const [
    getShoppingLists,
    { data, loading, refetch = () => {} }
  ] = useLazyQuery(getShoppingListsCustom);

  const [
    removeShoppingList,
    {
      data: removeShoppingListData,
      loading: removeShoppingListLoading,
      error: removeShoppingListError
    }
  ] = useDeleteShoppingListMutation();
  const { total = 1, limit = TABLE_LIMIT, offset = 0 } =
    data?.shoppingLists?.meta || {};

  const currentQuery = useRef<PageResponse>({
    pageIndex: 0,
    pageSize: limit,
    sortBy: []
  });

  const resolveBaseSortingList = (
    baseSortingList: Array<{ id: string; desc: boolean }>
  ) =>
    baseSortingList.map(
      (srt: any) =>
        ({
          field: srt.id,
          direction: srt.desc ? 'desc' : 'asc'
        } as BaseSorting)
    );

  useEffect(() => {
    getShoppingLists({
      variables: {
        filter: {
          limit,
          offset
        },
        sorting: resolveBaseSortingList(defaultSorting),
        onBehalfOfRetailerId: actingAsRetailer?.id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getShoppingLists, limit, offset, actingAsRetailer]);

  useEffect(() => {
    if (removeShoppingListData?.deleteShoppingList.success) {
      refetch();
    }
  }, [refetch, removeShoppingListData]);

  useFeedback(
    !!removeShoppingListData?.deleteShoppingList?.success,
    !!removeShoppingListError,
    'Inköpslistan borttagen',
    'Kunde inte ta bort inköpslistan'
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: PageResponse) => {
      const sorting = sortBy.length ? [sortBy[0]] : [];

      if (!sorting.length || sorting[0].id !== defaultSorting[0].id) {
        sorting.push(defaultSorting[0]);
      }

      if (pageIndex && pageSize) {
        currentQuery.current = { pageIndex, pageSize, sortBy };
      }
      const size = pageSize || currentQuery.current.pageSize;

      let index = currentQuery.current.pageIndex;
      if (pageIndex !== undefined && pageIndex !== null) {
        index = pageIndex;
      }

      getShoppingLists({
        variables: {
          filter: {
            limit: size,
            offset: index * size
          },
          sorting: resolveBaseSortingList(sorting),
          onBehalfOfRetailerId: actingAsRetailer?.id
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getShoppingLists, actingAsRetailer]
  );

  const importShoppingList = useCallback(
    (shoppingList: ShoppingList) => {
      shoppingList.items.forEach((item) => {
        const shoppingListItem = shoppingListResolvers.getProduct(item);

        const quantityInCart =
          cartItems.find(
            (cartItem) => shoppingListItem?.id === cartItem.item.id
          )?.quantity || 0;

        dispatch(
          addItem({
            sp: shoppingListItem,
            quantity: quantityInCart + item.amount,
            relatedVehicleId: item.relatedVehicle?.id,
            stockVehicleProductCount: item.stockVehicleProductCount,
            isCampaignEquipment: item.isCampaignEquipment
          })
        );
      });
      setShowImportFeedback(true);
    },
    [dispatch, cartItems]
  );

  const handleImportButton = useCallback(
    (shoppingList: ShoppingList) => {
      if (cartHasItems) {
        setSelectedImport(shoppingList);
        return;
      }

      importShoppingList(shoppingList);
    },
    [importShoppingList, cartHasItems]
  );

  useFeedback(
    showImportFeedback,
    false,
    'Inköpslistan importerad till varukorgen',
    '',
    () => setShowImportFeedback(false)
  );

  const hasWriteAccess = useCallback(
    (shoppingListRow) => {
      if (currentUser.role !== UserRole.DealySeller) {
        return true;
      }

      if (shoppingListRow.createdBy.id === currentUser.id) {
        return true;
      }

      return false;
    },
    [currentUser]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Namn',
        accessor: 'name'
      },
      {
        Header: 'Beskrivning',
        accessor: 'remark',
        Cell: ({ row: { original } }: any) => {
          return <ExpandableCell text={original.remark} />;
        }
      },
      {
        Header: 'Antal artiklar',
        accessor: '',
        disableSortBy: true,
        Cell: ({ row: { original } }: any) => {
          return <span>{sumBy(original.items, 'amount')}</span>;
        }
      },
      {
        Header: 'Skapad av',
        accessor: 'createdBy.name',
        Cell: ({ row: { original } }: any) => {
          return <span>{original.createdBy.name}</span>;
        }
      },
      {
        Header: 'Skapad den',
        accessor: 'createdAt',
        Cell: ({ row: { original } }: any) => {
          return <span>{formatDate(original.createdAt)}</span>;
        }
      },
      {
        Header: 'Funktioner',
        accessor: '',
        disableSortBy: true,
        style: { width: 70 },
        Cell: ({ row: { original } }: any) => (
          <OperationButtonsCell
            operations={[
              {
                onClick: () => handleImportButton(original),
                icon: 'add_shopping_cart',
                tip: 'Importera'
              },
              ...(hasWriteAccess(original)
                ? [
                    {
                      onClick: () => setSelectedItem(original),
                      icon: 'edit',
                      tip: 'Redigera'
                    },
                    {
                      onClick: () =>
                        removeShoppingList({ variables: { id: original.id } }),
                      icon: 'delete',
                      tip: 'Ta bort'
                    }
                  ]
                : [])
            ]}
          />
        )
      }
    ],
    [handleImportButton, removeShoppingList, hasWriteAccess]
  );

  return (
    <>
      <Helmet>
        <title>{`Webshop: ${label}`}</title>
      </Helmet>
      <ConfirmModal
        modalIsOpen={!!selectedImport}
        closeModal={() => setSelectedImport(null)}
        message="Detta lägger till inköpslistans artiklar till varukorgen"
        callBack={selectedImport && (() => importShoppingList(selectedImport))}
        confirmText="Importera"
      />
      <Drawer initial="closed" overlay />
      <section>
        <Container>
          <WebshopBreadcrumb crumbs={crumbs} />
          <div className={classNames(styles.main, styles.whiteBackground)}>
            <div className="grid grid--between">
              <p className={styles.tableInfo}>
                Sortera bland inköpslistorna genom att klicka på rubrikerna
              </p>
              {currentUser.role === UserRole.DealySeller &&
                !actingAsRetailer?.id && (
                  <p className={styles.infoRed}>
                    Välj återförsäljare för att se deras inköpslistor
                  </p>
                )}
            </div>
            <Table
              columns={columns}
              data={data?.shoppingLists?.edges || []}
              totalPages={Math.ceil(total / limit)}
              loading={loading || removeShoppingListLoading}
              fetchData={fetchData}
              showPagination
              manualSorting
            />
          </div>
        </Container>
      </section>
      {selectedItem && (
        <WebshopUpdateShoppingList
          isOpen={!!selectedItem}
          closeModal={() => {
            setSelectedItem(null);
          }}
          shoppingList={selectedItem}
        />
      )}
    </>
  );
};

export default WebshopShoppingLists;
