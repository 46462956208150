import { CartItem } from 'redux/cart';
import {
  WarrantyType,
  VehicleWarranty,
  PartWarranty,
  Row,
  ExternRow,
  RowTypes,
  PurchaseRefersType,
  GoodWillWarranty,
  OperationRowInput,
  ArticleRowInput,
  ExternRowInput,
  Equipment,
  SparePart,
  StockVehicle,
  ExplodedView,
  LegacyWarranty,
  BillOfMaterial,
  VolumeDiscount,
  ProductRule,
  MileageAllowanceMaintenanceRow,
  ExternalMaintenanceRow,
  ArticleMaintenanceRow,
  OperationMaintenanceRow,
  ShippingMaintenanceRow,
  Article,
  Fee
} from 'types';

export function isVehicleWarranty(warranty: any): warranty is VehicleWarranty {
  return (warranty as VehicleWarranty)?.type === WarrantyType.Vehicle;
}

export function isGoodWillWarranty(
  warranty: any
): warranty is GoodWillWarranty {
  return (warranty as GoodWillWarranty)?.type === WarrantyType.GoodWill;
}

export function isPriorApprovalWarranty(
  warranty: any
): warranty is GoodWillWarranty {
  return (warranty as GoodWillWarranty)?.type === WarrantyType.PriorApproval;
}

export function isPartWarranty(warranty: any): warranty is PartWarranty {
  return (warranty as PartWarranty)?.type === WarrantyType.Part;
}

export function isLegacyWarranty(warranty: any): warranty is LegacyWarranty {
  return (warranty as LegacyWarranty)?.type === WarrantyType.Legacy;
}

export function isExternRow(row: Row): row is ExternRow {
  return (row as ExternRow)?.type === RowTypes.Extern;
}

export function isOperationRowInput(
  rowInput: OperationRowInput | ArticleRowInput | ExternRowInput
): rowInput is OperationRowInput {
  return 'operationCodeId' in rowInput;
}

export function isArticleRowInput(
  rowInput: OperationRowInput | ArticleRowInput | ExternRowInput
): rowInput is ArticleRowInput {
  return 'orderItemId' in rowInput;
}

export function isExternRowInput(
  rowInput: OperationRowInput | ArticleRowInput | ExternRowInput
): rowInput is ExternRowInput {
  return 'purchaseRefersType' in rowInput;
}

export function isPurchaseRefersType(
  type?: PurchaseRefersType
): type is PurchaseRefersType {
  return (
    (type as PurchaseRefersType) === PurchaseRefersType.Work ||
    (type as PurchaseRefersType) === PurchaseRefersType.Part
  );
}

export function isStockVehicle(type?: Article): type is StockVehicle {
  return type?.__typename === 'StockVehicle';
}

export const isStockVehicleCartItem = (
  item: CartItem | CartItem<StockVehicle>
): item is CartItem<StockVehicle> => {
  return isStockVehicle(item.item);
};

export function isEquipment(type?: Article): type is Equipment {
  return type?.__typename === 'Equipment';
}

export function isBillOfMaterial(type?: Article): type is BillOfMaterial {
  return type?.__typename === 'BillOfMaterial';
}

export function isSparePart(type?: Article): type is SparePart {
  return type?.__typename === 'SparePart';
}

export function isFee(type?: Article): type is Fee {
  return type?.__typename === 'Fee';
}

export function isExplodedView(type: any): type is ExplodedView {
  return type?.__typename === 'ExplodedView';
}

export function isVolumeDiscount(
  type?: VolumeDiscount | ProductRule
): type is VolumeDiscount {
  return type?.__typename === 'VolumeDiscount';
}

export function isProductRule(
  type?: VolumeDiscount | ProductRule
): type is ProductRule {
  return type?.__typename === 'ProductRule';
}

export function isMileageAllowanceMaintenanceRow(
  type?: any
): type is MileageAllowanceMaintenanceRow {
  return type?.__typename === 'MileageAllowanceMaintenanceRow';
}

export function isExternalMaintenanceRow(
  type?: any
): type is ExternalMaintenanceRow {
  return type?.__typename === 'ExternalMaintenanceRow';
}

export function isArticleMaintenanceRow(
  type?: any
): type is ArticleMaintenanceRow {
  return type?.__typename === 'ArticleMaintenanceRow';
}

export function isOperationMaintenanceRow(
  type?: any
): type is OperationMaintenanceRow {
  return type?.__typename === 'OperationMaintenanceRow';
}

export function isShippingMaintenanceRow(
  type?: any
): type is ShippingMaintenanceRow {
  return type?.__typename === 'ShippingMaintenanceRow';
}
