import React, {
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useCallback
} from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { TextArea } from 'components/ui/forms';
import { Button, Tabs, Spinner, Empty } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import { getIsDealyUser } from 'redux/auth';
import {
  Warranty,
  MaintenanceTask,
  useMaintenanceTaskMessagesQuery,
  useWarrantyMessagesQuery,
  useSendWarrantyMessageMutation,
  useSendMaintenanceTaskMessageMutation,
  WorkshopMessageType,
  MaintenanceTaskMessagesQuery
} from 'types';
import { formatDate } from 'utils';

import styles from './MessageModal.module.scss';

interface MessageModalProps {
  type: 'Warranty' | 'MaintenanceTask';
  id: Warranty['id'] | MaintenanceTask['id'];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

type PartialMessage = NonNullable<
  MaintenanceTaskMessagesQuery['maintenanceTaskById']
>['internalMessages'][0];

export const MessageModal = ({
  type,
  id,
  isOpen,
  setIsOpen
}: MessageModalProps) => {
  const isDealyUser = useSelector(getIsDealyUser);
  const [newMessage, setNewMessage] = useState('');
  const [messageType, setMessageType] = useState(
    isDealyUser ? WorkshopMessageType.Internal : WorkshopMessageType.External
  );

  const isWarranty = type === 'Warranty';

  const messageQuery = isWarranty
    ? useWarrantyMessagesQuery
    : useMaintenanceTaskMessagesQuery;

  const sendMutation = isWarranty
    ? useSendWarrantyMessageMutation
    : useSendMaintenanceTaskMessageMutation;

  const {
    data,
    loading: loadingMessages,
    error: errorLoadingMessages
  } = messageQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
    skip: !isOpen
  });

  const [
    sendMessage,
    { loading: loadingSendMessage, error: errorSendMessage }
  ] = sendMutation({
    refetchQueries: ['WarrantyMessages', 'MaintenanceTaskMessages'],
    fetchPolicy: 'no-cache'
  });

  const loading = loadingMessages || loadingSendMessage;

  const { internalMessages, externalMessages } = useMemo(() => {
    if (data && 'warrantyById' in data)
      return {
        internalMessages: data.warrantyById?.internalMessages || [],
        externalMessages: data.warrantyById?.externalMessages || []
      };

    if (data && 'maintenanceTaskById' in data)
      return {
        internalMessages: data.maintenanceTaskById?.internalMessages || [],
        externalMessages: data.maintenanceTaskById?.externalMessages || []
      };

    return { internalMessages: [], externalMessages: [] };
  }, [data]);

  const submitMessage = useCallback(async () => {
    await sendMessage({
      variables: {
        ...(isWarranty
          ? { warrantyId: Number(id) }
          : { maintenanceTaskId: id }),
        text: newMessage,
        type: messageType
      } as any
    });
    setNewMessage('');
  }, [id, isWarranty, newMessage, sendMessage, messageType]);

  const renderMessages = useCallback((messages: PartialMessage[]) => {
    return messages.map((message) => (
      <div
        className={classNames(styles.message, {
          [styles.ownMessage]: message?.author?.retailer
        })}
      >
        <div className={styles.messageHeader}>
          <span>{message?.author.name}</span>
          <span className={styles.messageDate}>
            {formatDate(message?.createdAt)}
          </span>
        </div>
        {message?.text}
      </div>
    ));
  }, []);

  return (
    <DealyModal
      className={styles.root}
      anchor={DealyModalAnchor.RIGHT}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div>
        <div className={styles.titleRow}>
          <h4>Meddelanden</h4>
          <Button color="clear" onClick={() => setIsOpen(false)}>
            <i className="material-icons">close</i>
          </Button>
        </div>
        {isDealyUser ? (
          <Tabs
            initial={0}
            onActiveTabChange={(activeTab) =>
              setMessageType(activeTab as WorkshopMessageType)
            }
          >
            <Tabs.Panel id={WorkshopMessageType.Internal} label="Intern">
              {renderMessages(internalMessages)}
            </Tabs.Panel>
            <Tabs.Panel id={WorkshopMessageType.External} label="Extern">
              <div className={styles.warning}>
                <i className="material-icons">error_outline</i>
                <span>Meddelande till återförsäljare</span>
              </div>
              {renderMessages(externalMessages)}
            </Tabs.Panel>
          </Tabs>
        ) : (
          renderMessages(externalMessages)
        )}
        <Spinner className={styles.spinner} visible={loading} />
        {errorLoadingMessages && (
          <Empty
            title="Kunde inte hämta meddelanden"
            message="Något gick fel när meddelanden skulle hämtas"
          />
        )}
      </div>
      <div>
        {errorSendMessage && (
          <span className={styles.error}>
            Något gick fel. Kunde inte skicka meddelande
          </span>
        )}
        <TextArea
          className={styles.textArea}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          disabled={loadingSendMessage}
        />
        <Button
          onClick={submitMessage}
          fullWidth
          disabled={!newMessage.length || loading}
          color="alpha"
        >
          Skicka
        </Button>
      </div>
    </DealyModal>
  );
};
