import React from 'react';

import { useSelector } from 'react-redux';

import { Spinner, Empty, Tabs } from 'components/ui/general';
import { getSpecificItemCount } from 'redux/cart';
import { WebshopCarousel } from 'routes/Webshop/components';
import { StockVehicle, RelatedProduct, useRelatedEquipmentsQuery } from 'types';
import { isStockVehicle } from 'utils/typeGuards';

import styles from './WebshopRelatedArticles.module.scss';

interface WebshopRelatedArticlesProps {
  parentArticle: StockVehicle;
}

const WebshopRelatedArticles = ({
  parentArticle
}: WebshopRelatedArticlesProps) => {
  const parentCount = useSelector(
    getSpecificItemCount({ article: parentArticle })
  );

  const { data, loading, error } = useRelatedEquipmentsQuery({
    variables: { id: parentArticle.id }
  });

  const relatedEquipments = data?.relatedEquipments;

  if (loading) {
    return (
      <div className={styles.root}>
        <div className={styles.spinnerHolder}>
          <Spinner visible className={styles.spinner} />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.root}>
        <Empty
          className={styles.empty}
          icon="list_alt"
          title="Inget resultat hittades"
          message="Kunde inte hämta relaterade artiklar"
        />
      </div>
    );
  }

  if (!parentArticle || !relatedEquipments) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Tabs>
        <Tabs.Panel id="campaign" label="Kampanj">
          <WebshopCarousel
            relatedArticles={relatedEquipments.campaigns as RelatedProduct[]}
            parentId={parentArticle?.id}
            parentCount={parentCount}
            isCampaignEquipment
          />
        </Tabs.Panel>
        {isStockVehicle(parentArticle) && (
          <Tabs.Panel id="related" label="Tillbehör">
            <WebshopCarousel
              relatedArticles={
                relatedEquipments.relatedEquipments as RelatedProduct[]
              }
              parentId={parentArticle?.id}
              parentCount={parentCount}
            />
          </Tabs.Panel>
        )}
      </Tabs>
    </div>
  );
};

export default WebshopRelatedArticles;
