import { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';

import { FeedbackStatus, openFeedback } from '../redux/feedback';

export const useFeedback = (
  success: boolean,
  error: boolean | ApolloError,
  successMessage: string,
  errorMessage: string,
  resetFunction?: () => void,
  errorTranslator?: (arg0: any) => string
): void => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      let extendedMessage;
      if (
        error instanceof Error &&
        errorTranslator &&
        error.graphQLErrors[0].extensions?.errorVariant
      ) {
        extendedMessage = errorTranslator(
          error.graphQLErrors[0].extensions.errorVariant
        );
      }

      dispatch(
        openFeedback({
          status: FeedbackStatus.Error,
          message: errorMessage,
          extendedMessage
        })
      );
    } else if (success) {
      dispatch(
        openFeedback({
          status: FeedbackStatus.Success,
          message: successMessage
        })
      );
    }

    if (resetFunction) {
      resetFunction();
    }
  }, [
    dispatch,
    error,
    errorMessage,
    errorTranslator,
    resetFunction,
    success,
    successMessage
  ]);
};
