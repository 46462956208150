import { isLocalhost, isDemo, isStaging } from 'utils';

interface ApiEnvironment {
  apiUrl?: string;
}

const apiEnvironment: ApiEnvironment = {};

switch (true) {
  case !!process.env.REACT_APP_API_URL:
    apiEnvironment.apiUrl = process.env.REACT_APP_API_URL;
    break;

  case isLocalhost():
    apiEnvironment.apiUrl = 'http://localhost:8080';
    break;

  case isDemo():
  case isStaging():
    apiEnvironment.apiUrl = 'https://api.dealy.isdemo.se';
    break;

  default:
    apiEnvironment.apiUrl = process.env.REACT_APP_API_URL;
}

export default apiEnvironment;
