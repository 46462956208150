import React, { ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import { InfoCircleIcon } from 'assets/icons';
import { Tooltip } from 'components/ui/general';
import { STOCK_BREAK_POINT } from 'consts/webshop';
import { Article, IncomingDelivery, Stock, StockStatus } from 'types';
import { stockStatusToText, formatDate } from 'utils';
import { isStockVehicle, isFee } from 'utils/typeGuards';

import styles from './InStockMarker.module.scss';

const getApproximatedQuantity = (quantity: number) => {
  return quantity >= STOCK_BREAK_POINT
    ? `${STOCK_BREAK_POINT}st eller fler`
    : `Färre än ${STOCK_BREAK_POINT}st`;
};

const resolveInStock = (
  stock: Stock,
  approximateStockBalance: boolean | undefined
) => {
  if (approximateStockBalance) {
    return `${getApproximatedQuantity(
      stock.warehouse + stock.atRetailer
    )} i lager`;
  }

  if (stock.warehouse <= 0 && stock.atRetailer > 0) {
    return 'Endast i visnings/ konsignationslager, ordern bekräftas ej direkt';
  }

  return `${stock.warehouse + stock.atRetailer}st i lager`;
};

const resolveIncoming = (
  showDate: boolean,
  stock: Stock,
  incomingDeliveries: Array<IncomingDelivery>,
  approximateStockBalance: boolean | undefined
) => {
  let quantity = stock.incoming;
  let info = 'ankommande varor';

  if (showDate && !!incomingDeliveries.length) {
    quantity = incomingDeliveries[0].quantity;
    info = `ankommer den ${formatDate(incomingDeliveries[0].deliveryDate)}`;
  }

  return `${
    approximateStockBalance
      ? getApproximatedQuantity(quantity)
      : `${quantity}st`
  } ${info}`;
};

const resolveIncomingDeliveries = (
  incomingDeliveries: Array<IncomingDelivery>
) => (
  <div className={styles.deliveries}>
    {incomingDeliveries.map(({ quantity, deliveryDate }) => (
      <span>
        {quantity} nya tillgängliga {formatDate(deliveryDate)}
      </span>
    ))}
  </div>
);

interface InStockMarkerProps {
  product: Article;
  className?: string;
  approximateStockBalance?: boolean;
  showAsTooltip?: boolean;
}

const InStockMarker = ({
  product,
  className,
  approximateStockBalance,
  showAsTooltip = true
}: InStockMarkerProps) => {
  const stockStatus: {
    color: string;
    text: string;
    incomingDeliveries?: ReactNode;
  } = useMemo(() => {
    if (isFee(product)) {
      return { color: styles.green, text: '' };
    }

    const { stock } = product;
    const showApproximatedQuantity =
      approximateStockBalance && isStockVehicle(product);

    switch (stock.status) {
      case StockStatus.SwedenWarehouse:
        return {
          color: styles.green,
          text: resolveInStock(stock, showApproximatedQuantity)
        };

      case StockStatus.Incoming: {
        const incomingDeliveries = stock.incomingDeliveries
          ? [...stock.incomingDeliveries].sort(
              (a, b) => Date.parse(a.deliveryDate) - Date.parse(b.deliveryDate)
            )
          : [];

        return {
          color: styles.yellow,
          text: resolveIncoming(
            !isStockVehicle(product),
            stock,
            incomingDeliveries,
            showApproximatedQuantity
          ),
          incomingDeliveries:
            !isStockVehicle(product) &&
            !!incomingDeliveries.length &&
            resolveIncomingDeliveries(incomingDeliveries)
        };
      }

      case StockStatus.Orderable:
        return {
          color: styles.red,
          text: stockStatusToText(
            StockStatus.Orderable,
            isStockVehicle(product)
          )
        };

      default: {
        const msg: never = stock.status;
        throw new TypeError(`unknown stock status type: '${msg}'`);
      }
    }
  }, [approximateStockBalance, product]);

  return (
    <>
      <div className={classNames(styles.stockBalance, className)}>
        <svg
          viewBox="0 0 100 100"
          className={`${styles.dot} ${stockStatus.color}`}
        >
          <circle cy="50" cx="50" r="50" />
        </svg>
        <div className={styles.textRows}>
          <span>{stockStatus.text}</span>
        </div>
        {!!showAsTooltip && !!stockStatus.incomingDeliveries && (
          <Tooltip
            trigger="hover"
            placement="bottom"
            tip={stockStatus.incomingDeliveries}
            classNameTrigger={styles.infoIcon}
          >
            <InfoCircleIcon className={styles.infoIcon} />
          </Tooltip>
        )}
      </div>
      {!showAsTooltip && stockStatus.incomingDeliveries && (
        <div>{stockStatus.incomingDeliveries}</div>
      )}
    </>
  );
};

export default InStockMarker;
